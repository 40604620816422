import { CurrentUser, Roles } from "modules/user/types";

export interface AuthCredentials {
  email: string;
  password: string;
}

export type User = CurrentUser;
export { Roles as UserRole };

export interface AuthState {
  user?: User | null;
  isLoggedIn: boolean;
  error: boolean;
  loading: boolean;
  login: (payload: { email: string; password: string }) => string | void;
  logout: () => void;
  checkedOnFirstLoad: boolean;
  setCurrentUser: (user: User) => void;
}

export enum AuthActionType {
  LOGIN = "AUTH__LOGIN",
  LOGOUT = "AUTH__LOGOUT",
  LOADING = "AUTH__LOADING",
  ERROR = "AUTH__ERROR",
  CURRENT_USER = "AUTH__CURRENT_USER",
}

export type AuthAction =
  | { type: UserActionType.LOGIN; payload: any }
  | { type: UserActionType.LOGOUT; payload: undefined }
  | { type: UserActionType.LOADING; payload: any }
  | { type: UserActionType.ERROR; payload: any }
  | { type: UserActionType.CURRENT_USER; payload: any };
