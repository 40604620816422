import React, { useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { NotificationProvider } from "shared/src/lib/notification";
import { ThemeProvider } from "styled-components";
import { PrevPageKey } from "lib/constants";
import { AuthProvider } from "../lib/auth/authContext";
import theme from "../lib/theme/index";
import { ViewProvider } from "../lib/view";
import "@reach/dialog/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "../lib/theme/reset.css";
import "../../public/@fortawesome/fontawesome-pro/css/all.min.css";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(function savePrevPage() {
    function handleRouteChange() {
      localStorage.setItem(
        PrevPageKey,
        window.location.href.replace(/^.*\/\/[^\/]+/, ""),
      );
    }

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      localStorage.removeItem(PrevPageKey);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ViewProvider>
            <NotificationProvider>
              <Component {...pageProps} />
            </NotificationProvider>
          </ViewProvider>
        </AuthProvider>
      </ThemeProvider>
      <Analytics />
    </>
  );
}

export default MyApp;
