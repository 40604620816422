import type { Fetcher } from "lib/hooks/useAsyncData";
import type { CurrentUser } from "../types";

const getCurrentUser: Fetcher<CurrentUser> = async () => {
  console.debug({
    message: "CURRENT USER REQUEST",
  });

  try {
    const resp = await fetch("/api/users/me", { method: "GET" });

    const respData = await resp.json();

    if (!resp.ok) {
      throw new Error(respData.message || resp.statusText);
    }

    return respData.data;
  } catch (err) {
    let errorMessage;
    if (err instanceof Error) errorMessage = err.message;
    else if (typeof err === "string") errorMessage = err;
    else errorMessage = "Something went wrong";

    throw new Error(errorMessage);
  }
};

export default getCurrentUser;
