import React, { createContext } from "react";
import { ToastContainer, toast } from "react-toastify";

type NotificationContextProps = {
  notify: (type: "success" | "warn" | "error" | "info", text: string) => void;
};

const NotificationContext = createContext<NotificationContextProps>({
  notify: () => {},
});
function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const notify = (
    type: "success" | "warn" | "error" | "info" | "",
    text: string,
  ) => {
    switch (type) {
      case "":
        return toast(text);
      case "success":
        return toast.success(text);
      case "error":
        return toast.error(text);
      case "warn":
        return toast.warn(text);
      case "info":
        return toast.info(text);
      default:
        return null;
    }
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </NotificationContext.Provider>
  );
}
export { NotificationProvider, NotificationContext as default };
