export enum Roles {
  Admin = "ADMIN",
  Researcher = "RESEARCHER",
  User = "REGISTERED",
}

export enum Notifications {
  Once_week = "once_week",
  Twice_week = "twice_week",
  Once_month = "once_month",
  Once_year = "once_year",
}

export interface UsersQuery {
  size: number;
  after: Record<string, any> | null; // typeof q.Ref;
  before: Record<string, any> | null; // typeof q.Ref;
  filter?: Record<string, string[] | string | null>;
}

export interface User {
  id: string;
  name: string;
  email: string;
  username: string;
  role: Roles;
  createdBy: {
    username: string;
  };
  lastEditAt: string;
  createdAt: string;
}

export interface CurrentUser {
  id: string;
  email: string;
  username: string;
  name: string;
  role: Roles;
  notifications: Notifications;
}

export interface UserFormValues {
  id?: string; // used in edit userw

  username: string;
  name: string;
  email: string;
  role: Roles;

  password?: string; // used in create user
}
